import { useState, useEffect } from 'react';

export const useScrollBackgroundColor = (initialColor) => {
  const [backgroundColor, setBackgroundColor] = useState(initialColor);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPercentage = (window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) * 100;
      const colors = ['#0A0519', '#080D36', '#300836', '#362408', '#082e36', '#082e36'];
      const index = Math.min(Math.floor(scrollPercentage / (100 / (colors.length - 1))), colors.length - 1);
      setBackgroundColor(colors[index]);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [initialColor]);

  return backgroundColor;
};
