import React, { useState, useEffect } from "react";
import { Element } from "react-scroll";
import { useScrollBackgroundColor } from "../../components/scrollUtils/scrollUtils";
import "./landing-page.scss";
import Particles from "../../components/Particles";
import meshBg from "../../assets/home/hero-illustration-v3.svg";
import roundMeshBg from "../../assets/home/bg-art.svg";
import logo from "./../../assets/Logo.png";
import scrollDown from "./../../assets/scrollDown.svg";
import InteractiveBg from "../../components/InteractiveBg";

const LandingPage = () => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const initialColor = '#0A0519'; // Set the initial color for this page
  const backgroundColor = useScrollBackgroundColor(initialColor);

  const scrollToNextSection = () => {
    const workSection = document.getElementById("work");
    if (workSection) {
      workSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <Element id="home">
      <div className="hero-section" style={{ backgroundColor, transition: 'background-color 0.5s' }}>
        <InteractiveBg className={'interactive-bg'} />
        {/* <div className="round-mesh-bg-wrapper" style={imageLoaded ? null : { display: 'none' }}> */}
        {/* <img src={meshBg} alt="bg" onLoad={() => setImageLoaded(true)} className="mesh-bg" /> */}
        {/* <img src={roundMeshBg} alt="bg" onLoad={() => setImageLoaded(true)} className="round-mesh-bg" /> */}
        {/* </div> */}
        {/* <Particles className="particles" quantity={50} /> */}
        <div className="center-box">
          <img src={logo} alt="Techfy" />
          <div className="key-points">
            <span>Building</span>
            <span>Success</span>
            <span>Together</span>
          </div>
        </div>
        <div className="scroll-down" onClick={scrollToNextSection}>
          <div className="arrow bounce">
            <img src={scrollDown} alt="arrow down" width="30px" height="30px" />
          </div>
        </div>
      </div>
    </Element>
  );
};

export default LandingPage;
