import React, { useState } from "react";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { navlinks } from "../../data";
import { Link } from "react-scroll";
import "./style.scss";
import { Stack, Container, Box, useMediaQuery } from "@mui/material";
import { Button } from "../buttons";
import Toggler from "./comps/Toggler";
import Sidebar from "./comps/Sidebar";

const Navbar = () => {
  const mobile = useMediaQuery('(max-width:992px)');
  const [active, setActive] = React.useState(false);

  return (
    <Box className={'navbar-wrapper'}>
      <Container>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          gap={3}
          sx={{
            py: 2.5
          }}
        >
          <Logo />
          {mobile ? (<>
            <Toggler active={active} setActive={setActive} />
            <Sidebar open={active} toggleDrawer={setActive} />
          </>) : (<>
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'center'}
              className="nav-links"
            >
              {navlinks.map((link, i) => (
                <Link
                  key={i}
                  activeClass="active"
                  to={link.to}
                  spy={true}
                  smooth={true}
                  offset={link.offset}
                  duration={500}
                  className="nav-link"
                >
                  <span>{link.title}</span>
                </Link>
              ))}
            </Stack>

            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
            >
              <Button
                variant="contained"
                size="large"
              >
                Let's Connect
              </Button>
            </Link>
          </>)}
        </Stack>
      </Container>
    </Box>
  )
}

export default Navbar
