import React from 'react';
import { Drawer, Stack } from '@mui/material';
import { Link } from "react-scroll";
import { Button } from '../../buttons';
import { navlinks } from '../../../data';
import './styles.scss';

const Sidebar = ({ open, toggleDrawer }) => {
    return (
        <Drawer
            open={open}
            onClose={() => toggleDrawer(false)}
            disableScrollLock
            hideBackdrop
            ModalProps={{
                sx: {
                    height: 'calc(100svh - 83px)',
                    top: '83px',
                }
            }}
            PaperProps={{
                sx: {
                    position: 'relative',
                    maxWidth: '60%',
                    background: 'rgba(0,0,0, 0.6)',
                    borderRight: 1,
                    borderColor: 'rgba(256,256,256, 0.2)',
                    backdropFilter: 'blur(12px)',
                    p: 3,
                }
            }}
        >
            <Stack
                flexGrow={1}
                className='sidebar-links'
            >
                {navlinks.map((link, i) => (
                    <Link
                        key={i}
                        activeClass="active"
                        to={link.to}
                        spy={true}
                        smooth={true}
                        offset={link.offset}
                        duration={500}
                        className="sidebar-link"
                    >
                        <span>{link.title}</span>
                    </Link>
                ))}
            </Stack>
            <Link
                to="contact"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
                onClick={() => toggleDrawer(false)}
            >
                <Button
                    variant="contained"
                    size="large"
                    fullWidth
                >
                    Let's Connect
                </Button>
            </Link>
        </Drawer>
    )
}

export default Sidebar
