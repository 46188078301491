import React, { useRef, useEffect, useState } from 'react'
import { Box } from "@mui/material";
import Spline from '@splinetool/react-spline';

const InteractiveBg = (props) => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const spline = useRef();
    const inputMin = 0;
    const inputMax = 900;
    const outputMin = 1;
    const outputMax = 1.5;

    function onLoad(splineApp) {
        spline.current = splineApp;
    }


    // const handleScroll = () => {
    //     const position = window?.pageYOffset;
    //     if (spline && (position >= 0 && position < 900)) {
    //         const output = ((position - inputMin) * (outputMax - outputMin)) / (inputMax - inputMin) + outputMin
    //         spline?.current?.setZoom(output?.toFixed(2));
    //     }
    // };


    // useEffect(() => {
    //     window.addEventListener('scroll', handleScroll, { passive: true });

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);

    return (
        <Box {...props}>
            <Spline
                // scene="https://prod.spline.design/KjStHYtpk9Zd2yW3/scene.splinecode"
                scene="https://prod.spline.design/Hr4H1bS3kTTKBcBz/scene.splinecode"
                onLoad={onLoad}
            />
        </Box>
    )
}

export default InteractiveBg
