import {
  Box,
  Container,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { Link as ScrollLink } from "react-scroll";
import { useScrollBackgroundColor } from "../../components/scrollUtils/scrollUtils";
import twitter from "../../assets/twitterIcon.svg";
import facebook from "../../assets/facebookIcon.svg";
import EmailIcon from '@mui/icons-material/Email';
import linkedIn from "../../assets/linkedinIcon.svg";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Instagram from "../../assets/instagramIcon.svg";
import img1 from "../../assets/image 87.png";
import img2 from "../../assets/image 88.png";
import img3 from "../../assets/image 89.png";
import techfyLogo from "../../assets/TechfyLogo.svg";
import "./footer.scss";
import { Button } from "react-scroll";

// Now you can use Blog, Contact, Industries, Services, Work, and LandingPage in your footer.js

const Footer = () => {

  const company = [
    "Home",
    "Clients",
    "Services",
    "Industries",
    "Contact us",
  ];

  const renderList = (items) => {
    return (
      <List>
        {items.map((item, index) => (
          <ListItem
            button
            key={index}
            className="footerSectionList"
          >
            <ScrollLink
              to={item === 'Contact us' ? 'contact' : item.toLowerCase()}
              spy={true}
              smooth={true}
              duration={500}
            >
              <ListItemText
                primary={
                  <Typography style={{ fontFamily: "Gilroy-Regular", textDecorationLine: "underline", lineHeight: "0.7", fontSize: "1.1rem" }}>
                    {item}
                  </Typography>
                }
              />
            </ScrollLink>
          </ListItem>
        ))}
      </List>
    );
  };
  return (
    <Box
      className="footer"

      sx={{ padding: { xl: '2rem' } }}
    >
      <Container
        sx={{
          paddingLeft: { lg: "10%", xl: "24px" },
          paddingRight: { lg: "10%", xl: "24px" },
        }}
      >
        <Box
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "column" },
              alignItems: { xs: "left", md: "flex-start" },
              justifyContent: { xs: "space-between", md: "" },
              marginBottom: { xs: "-50px", md: "-05px", xl: "-50px" },

            }}
          >
            <img src={techfyLogo} alt="Example" style={{ width: "130px", marginBottom: "20px" }} />
            <Link href="mailto:hello@techfy.io" className="company-email" style={{ marginBottom: '4px' }}>
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: '0px', marginRight: '15px', marginBottom: "5px" }}>
                <EmailIcon style={{ marginRight: '12px' }} />
                <span style={{ fontSize: '21px' }}>hello@techfy.io</span>
              </div>
            </Link>



            <Link href="https://www.linkedin.com/company/techfy-io/" className="social-media-icons">
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: '0px', marginRight: '14px' }}>

                <LinkedInIcon style={{ color: 'white', fontSize: '25px' }} />
                <span style={{ marginLeft: '11px', fontSize: '21px', color: 'white' }}>linkedin.com/techfy-io</span>
              </div>
            </Link>





          </Box>

          <Grid container

            sx={{
              marginTop: { xs: "50px", md: "8px", xl: "50px" },
              marginBottom: { xs: "-40px", md: "-7px", xl: "-40px" }
            }}>
            <Grid item md={12} lg={6} sx={{ marginTop: { xs: "70px", md: "10px", xl: "70px" } }}>

              <Grid container spacing={5} sx={{ display: "flex", }}  >
                <Grid item className="contact" marginBottom="10px" xs={12} sm={4}>
                  <Box className="logo-text"  >
                    <img src={img2} alt="" />
                    <Typography variant="subtitle1" className="side-text">
                      usa
                    </Typography>
                  </Box>
                  <Typography variant="body1" className="bottom-text">
                    New york
                  </Typography>
                  <Typography variant="body1" marginBottom="15px" sx={{
                    letterSpacing: "0.1125rem",
                  }}>
                    <Link href="tel:+781866-5723" className="bottom-text" style={{ textDecoration: 'underline' }} >
                      +17542767261
                    </Link>
                  </Typography>
                </Grid>

                <Grid item className="contact" xs={12} sm={4}>
                  <Box className="logo-text">
                    <img src={img1} alt="" />
                    <Typography variant="subtitle1" className="side-text">
                      uk
                    </Typography>
                  </Box>
                  <Typography variant="body1" className="bottom-text">
                    London
                  </Typography>
                  <Typography variant="body1" sx={{
                    letterSpacing: "0.1125rem",
                  }}>
                    <Link href="tel:+781866-5723" className="bottom-text" style={{ textDecoration: 'underline' }}>
                      +447542767261
                    </Link>
                  </Typography>
                </Grid>
                <Grid
                  item

                  className="contact"
                  xs={12}
                  sm={4}

                >
                  <Box className="logo-text" >
                    <img src={img3} alt="" />
                    <Typography variant="subtitle1" className="side-text">
                      Pakistan
                    </Typography>
                  </Box>
                  <Typography variant="body1" className="bottom-text">
                    Lahore
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      letterSpacing: "0.1125rem",
                    }}
                  >
                    <Link href="tel:+92306863646" className="bottom-text" style={{ textDecoration: 'underline' }}>
                      +923068636467
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              md={12}
              lg={6}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: { xs: "70px", md: "0px" },
              }}

            >
              <Box  >
                <Typography variant="h6" className="footerSectionHeading">
                  Company
                </Typography>

                {renderList(company)}
              </Box>
            </Grid>
          </Grid>

          <Grid container sx={{ marginLeft: "-9px", marginTop: { xs: "70px", md: "10px", xl: "70px" } }}>
            <Grid item md={12} lg={6}>

              <Typography
                className="bottomText"
                sx={{ marginTop: { xs: "20px", md: "" }, letterSpacing: "0.1125rem", }}

              >
                © 2024 | All Rights Reserved
              </Typography>
            </Grid>

            <Grid
              item
              md={12}
              lg={6}

              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "flex-end",
                marginTop: { xs: "20px", md: "" },

              }}

            >
              <Typography className="bottomText" sx={{ textDecorationLine: "underline", letterSpacing: "0.1125rem", }}>Privacy Policy</Typography>
              <Typography className="bottomText" >.</Typography>
              <Typography className="bottomText" sx={{ textDecorationLine: "underline", letterSpacing: "0.1125rem", }}>Terms & Conditions</Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
