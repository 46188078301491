import { Button as MuiButton, styled } from "@mui/material";

export const Button = styled(MuiButton)(({ theme }) => ({
    fontFamily: 'Gilroy-Bold',
    "&.MuiButton-contained": {
        clipPath: 'polygon(18px 0, 100% 0, 100% calc(100% - 18px), calc(100% - 18px) 100%, 0 100%, 0 18px)',
        borderRadius: 0,
        fontSize: 18,
        lineHeight: 1,
        padding: '19px 20px',
        color: '#000000',
        backgroundColor: '#FFFFFF',
        position: 'relative',
        isolation: 'isolate',
        transition: 'color 500ms cubic-bezier(0.4, 0, 0.2, 1)',
        letterSpacing: '1px',
        "&:before": {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: 'linear-gradient(45deg, rgb(0, 229, 255) 27%, rgb(255, 0, 199) 52%, rgb(255, 199, 0) 80%)',
            opacity: 0,
            zIndex: -1,
            transition: 'opacity 500ms cubic-bezier(0.4, 0, 0.2, 1)',
        },
        "&:hover": {
            backgroundColor: '#FFFFFF',
            "&:before": {
                opacity: 1,
            }
        }
    }
}));